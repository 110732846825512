import React from 'react'
import'./header.css';
import people from '../../assets/Group_81.png'
import ai from '../../assets/Illustration.png'

const Header = () => {
  return (
    <div className="gptx__header section__padding" id="home">
      <div className="gptx__header-content">
        <h1 className="gradient__text">Let’s Build Something amazing with GPT-X OpenAI</h1>
        <p>AI enhances productivity by automating tasks, analyzing vast data sets, and providing insights. It improves decision-making, personalizes experiences, and assists in solving complex problems across industries, making daily tasks more efficient.</p>
        <div className="gptx__header-content__input">
          <input type="email" placeholder="Your Email Address"/>
          <button type="button">Get Started</button>
        </div>

        <div className="gptx__header-content__people">
          <img src={people} alt="people" />
          <p>1,600 people requested access a visit in last 24 hours</p>
        </div>
      </div>
      <div className="gptx__header-image">
        <img src={ai} alt="ai" />
      </div>
    </div>
  )
}

export default Header