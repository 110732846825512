import React from 'react'
import Feature from '../../components/feature/Feature.jsx';
import'./features.css';

const featuresData = [
  {
  title: 'Exploring the Boundless Possibilities of GPT',
  text: "GPT's versatility opens doors to creative writing, personalized learning, and advanced data analysis across various fields.",
  },
  {
    title: 'How GPT is Shaping the Future',
    text: 'With GPT, the potential for enhanced customer service, automated content generation, and insightful data interpretation is limitless.',
  },
  {
    title: 'The Future of AI',
    text: 'GPT offers groundbreaking possibilities in education, providing tailored learning experiences and interactive tutoring for diverse needs.',
  },
  {
    title: 'GPT Unleashed',
    text: "From innovative business solutions to creative projects, GPT's applications are transforming industries and enhancing productivity.",
  }
]

const Features = () => {
  return (
    <div className="gptx__features section__padding" id="features">
      <div className="gptx__features-heading">
        <h1 className="gradient__text">The Future is Now. Take The First Step Today and Turn Virtual AI Into your Reality.</h1>
        <p>Request Early Access to Get Started</p>
      </div>
      <div className="gptx__features-container">
        {featuresData.map((item, index) => (
          <Feature title={item.title} text={item.text} key={item.title + index} />
        ))}
      </div>
    </div>
  )
}

export default Features