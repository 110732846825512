import React from 'react'
import possibilityImage from '../../assets/Feature_Image.png';
import'./possibility.css';

const Possibility = () => {
  return (
    <div className="gptx__possibility section__padding" id="possibility">
      <div className="gptx__possibility-image">
        <img src={possibilityImage} alt="possibility" />
      </div>
      <div className="gptx__possibility-content">
        <h4>Request Early Access To Get Started</h4>
        <h1 className="gradient__text">The possibilities are beyond your imagination</h1>
        <p>GPT surpasses imagination with its ability to generate human-like text, solve complex problems, and adapt to diverse tasks, continuously expanding its impact across various fields.</p>
        <h4>Request Early Access to Get Started</h4>
      </div>
    </div>
  )
}

export default Possibility