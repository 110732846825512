import React from 'react'
import gptxLogo from '../../assets/GPT-3.svg'
import'./footer.css';

const Footer = () => {
  return (
    <div className="gptx__footer section__padding">
      <div className="gptx__footer-heading">
        <h1 className="gradient__text">Step into the future today</h1>
      </div>
      <div className="gptx__footer-btn">
        <p>Request Early Access</p>
      </div>
      <div className="gptx__footer-links">
        <div className="gptx__footer-links_logo">
          <img src={gptxLogo} alt="logo" />
          <p>Crechterwoord K12 182 DK Alknjkcb, All Rights Reserved</p>
        </div>
        
        <div className="gptx__footer-links_div">
          <h4>Links</h4>
          <p>ItHas</p>
          <p>TakenOver</p>
          <p>AllOf</p>
          <p>TheCompany</p>
        </div>

        <div className="gptx__footer-links_div">
          <h4>Company</h4>
          <p>Terms & Conditions</p>
          <p>Privacy Policy</p>
          <p>Contact</p>
        </div>

        <div className="gptx__footer-links_div">
          <h4>Get in Touch</h4>
          <p>Instagram</p>
          <p>Azkaban, North Sea 000000</p>
          <p>1 (234) 567-8910</p>
          <p>email@gmail.com</p>
        </div>

      </div>
        <div className="gptx__footer-copywrite">
          <p>© 2021 GPT-X. All rights reserved. Please send Help</p>
        </div>
    </div>
  )
}

export default Footer