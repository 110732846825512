import React from 'react'
import { Article } from '../../components'
import { blog01, blog02, blog03, blog04, blog05 } from './imports';
import'./blog.css';

const Blog = () => {
  return (
    <div className="gptx__blog section__padding" id="blog">
      <div className="gptx__blog-heading">
        <h1 className="gradient__text">See what people are saying about GPT-X.</h1>
      </div>
      <div className="gptx__blog-container">
        <div className='gptx__blog-container_groupA'>
          <Article imgUrl={blog01} date="Oct 23, 2666" title="GPT-X and Open AI is the future. Let us exlore how."/>
        </div>
        <div className='gptx__blog-container_groupB'>
          <Article imgUrl={blog02} date="July 4, 2049" title="Unlocking the Future: The Limitless Potential of OpenAI."/>
          <Article imgUrl={blog03} date="Sep 26, 2000" title="How GPT-X is Revolutionizing Industries with Cutting-Edge Innovations."/>
          <Article imgUrl={blog04} date="Jan 11, 3011" title="GPT-X's Role in Shaping Tomorrow's Technology and Human Collaboration."/>
          <Article imgUrl={blog05} date="Feb 14, 2020" title="Exploring GPT-X's Expanding Horizons: Transforming Creativity, Business, and Learning."/>
        </div>
      </div>
    </div>
  )
}

export default Blog