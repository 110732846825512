import React from 'react';
import Feature from '../../components/feature/Feature.jsx';
import'./whatGPTx.css';

const WhatGPTx = () => {
  return (
    <div className="gptx__whatgptx section__margin" id="wgptx">
      <div className="gptx__whatgptx-feature">
        <Feature title="What is GPTx" text="GPT-X is a next-generaltion AI language model created to assist with answering questions, generating content, problem-solving, and providing information on various topics. Its purpose is to facilitate learning, creativity, and communication through natural, intuitive interactions"/>
      </div>
      <div className="gptx__whatgptx-heading">
        <h1 className="gradient__text">The possibilities are beyond your imagination.</h1>
        <p>Explore the Library</p>
      </div>
      <div className="gptx__whatgptx-container">
        <Feature title="Chatbots"text="Chatbots enhance customer service by providing instant responses, improving efficiency, reducing costs, and boosting engagement." />
        <Feature title="Knowledgebase" text="AI enhances knowledgebases by organizing data, automating queries, providing instant answers, and improving overall information accessibility." />
        <Feature title="Education" text="AI in education personalizes learning, automates administrative tasks, enhances engagement, provides real-time feedback, and supports adaptive learning experiences." />
      </div>
    </div>
  )
}

export default WhatGPTx